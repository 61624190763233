var cosmosConfig = {}

cosmosConfig.endpoint = 'https://dev-bc-subsidy-comos-db.documents.azure.com:443/'
cosmosConfig.key = 'v19hjaL5KxC1VE7HTQvWNfj1Q8D7zzsIkpeTY29FGuTHBWgfelHckTdWz2AZAdk7UONgCK0M5fDJACDboZTHjw=='

cosmosConfig.database = {
  id: 'dev-bc-db'
}

cosmosConfig.casesContainer = {
  id: 'cases'
}

cosmosConfig.bcContainer = {
  id: 'bargainingCouncils'
}

cosmosConfig.invoiceContainer = {
  id: 'invoices'
}

cosmosConfig.subsidyRatesContainer = {
  id: 'subsidyRates'
}

cosmosConfig.auditLogsContainer = {
  id: 'auditTrail'
}

module.exports = cosmosConfig